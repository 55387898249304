<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="320"
  >
    <v-card>
      <v-card-title class="headline">
        {{ $t('app.equipos.add_to_tournament_question') }}
      </v-card-title>
      <v-card-actions>
        <v-btn
          color="red darken-1"
          text
          @click="cancel"
        >
          {{ $t('app.general.no') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="green dark"
          @click="confirm"
        >
          {{ $t('app.general.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EquiposAddToTournamentQuestion",
  data () {
    return {
      equipo: false,
      dialog: false
    }
  },
  mounted() {
    EventBus.$on('equipos-add-to-tournament-question', (item) => {
      this.equipo = item
      this.dialog = true
    })
  },
  methods: {
    async confirm () {
      this.dialog = false
      EventBus.$emit('equipos-add-to-tournament', this.equipo)
    },
    async cancel () {
      this.dialog = false
      EventBus.$emit('reload-items')
    },
  }
}
</script>

<style scoped>

</style>
